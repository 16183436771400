exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-contentful-article-slug-js": () => import("./../../../src/pages/case-studies/{contentfulArticle.slug}.js" /* webpackChunkName: "component---src-pages-case-studies-contentful-article-slug-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-contentful-article-slug-js": () => import("./../../../src/pages/insights/{contentfulArticle.slug}.js" /* webpackChunkName: "component---src-pages-insights-contentful-article-slug-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-playbook-page-slug-js": () => import("./../../../src/pages/playbook/{Page.slug}.js" /* webpackChunkName: "component---src-pages-playbook-page-slug-js" */),
  "component---src-pages-smart-web-apps-js": () => import("./../../../src/pages/smart-web-apps.js" /* webpackChunkName: "component---src-pages-smart-web-apps-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

